import { ButtonLooks, IconButton, FontIcon, FontIcons, Placements, StandardTooltip } from '@brandfolder/react';
import { t, Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';
import ReactPaginate from 'react-paginate';

import moveAsset from '@api/sections/move-asset';
import syncInDesign from '@api/v3/printui';
import getResourceAssets from '@api/v4/resources/assets';
import Checkbox from '@components/common/checkbox/main';
import { AssetUploader } from '@components/show_page/sections/asset/AssetUploader';
import { SearchOperatorOptions } from '@components/show_page/sections/section_search/SearchTypes';
import oldType from '@helpers/oldType';
import { Brandfolder, processCustomFields, refreshSection } from '@helpers/show_page_helpers';
import { getCurrentUserIsAdminPlus } from '@helpers/user';

import Asset from './asset/asset';
import SectionsContext from './sectionsContext';
import SectionShroud from './sectionShroud';

import './styles/section.scss';

class Section extends React.Component {
  assetRefs = {}

  constructor(props) {
    super(props);

    this.state = {
      isSyncingInDesign: false,
      insertCaret: null,
      isRendered: props.isRendered,
    };
  }

  componentDidMount() {
    this.addReloadListener();
  }

  componentWillUnmount() {
    this.removeReloadListener();
  }

  handleToggleSelected = (options = {}) => {
    const { assetKeys, selectAllPages, viewOnlyAssetKeys } = options;
    const { section, toggleSelected } = this.props;

    toggleSelected({
      assetKeys,
      selectAllPages,
      sectionKey: section.section_key,
      viewOnlyAssetKeys
    });
  }

  toggleSelectedAssets = async (type, { activeLabelKey }) => {
    const {
      activeSection,
      allAssetsSelected,
      downloadRequestId,
      section,
      toggleSelected,
      userViewOptions,
    } = this.props;

    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

    const selectCurrentPage = type === 'page' && (allAssetsSelected || !this.sectionSelected());
    const entireSection = type === 'all';

    const getAllAssetKeys = async (viewOnly = undefined) => {
      const { searchQuery } = this.props;
      const { searchOperator, showSections } = userViewOptions;

      const params = {
        ...searchQuery && { search: searchQuery },
        ...searchQuery && { search_operator: (searchOperator === SearchOperatorOptions.Strict || searchOperator === SearchOperatorOptions.Quoted)
          ? SearchOperatorOptions.OR
          : searchOperator
        },
        digest: BFG.manifestDigest,
        download_request_key: BFG.downloadRequestKey,
        ...activeLabelKey && { labelKeys: [activeLabelKey] },
        onlyKeys: true,
        strict_search: searchOperator === SearchOperatorOptions.Strict,
        ...viewOnly && { view_only: true }
      };

      const options = {
        activeSection,
        isCollection: BFG.resource.type === 'collection',
        resourceKey: BFG.resource.key,
        resourceType: BFG.resource.type,
        sectionKey: section.section_key,
        showSections,
      };

      const { assets } = await getResourceAssets(params, options);

      return assets;
    };

    const allPromise = viewOnlyAssetsEnabled && !downloadRequestId
      ? Promise.all([getAllAssetKeys(), getAllAssetKeys(true)])
      : Promise.all([getAllAssetKeys()])

    if (allAssetsSelected && selectCurrentPage) {
      // we're going from all pages to just the first;
      // for this to work we need to toggle off everything,
      // then proceed with the normal "toggle the first page" dance
      const values = await allPromise;

      toggleSelected({
        assetKeys: values[0],
        viewOnlyAssetKeys: values[1] || []
      });
    }

    const selectAllPages = entireSection && !allAssetsSelected;

    if (entireSection) {
      const values = await allPromise;

      toggleSelected({
        assetKeys: values[0],
        selectCurrentPage,
        selectAllPages,
        sectionKey: section.section_key,
        viewOnlyAssetKeys: values[1] || []
      });
    } else {
      toggleSelected({
        assetKeys: this.getAssetKeys(),
        selectCurrentPage,
        selectAllPages,
        sectionKey: section.section_key,
        viewOnlyAssetKeys: !downloadRequestId ? this.getViewOnlyAssetKeys() : []
      });
    }
  };

  goToPage = (data) => {
    this.props.onUpdate({ page: data.selected + 1 });
  }

  maxPageLimit = () => {
    // Postgres struggles with excessive OFFSETs in queries and will timeout
    const { assets, totalPages } = this.props;
    const backendOffsetLimit = 10000;
    const paginationSize = parseInt(assets.length || 32);

    if ((paginationSize * totalPages) > backendOffsetLimit) {
      return Math.floor(backendOffsetLimit / paginationSize);
    }

    return totalPages;
  }

  syncInDesign = () => {
    this.setState({ isSyncingInDesign: true });
    const { id } = this.props.section;
    syncInDesign(id).then(() => {
      this.props.onUpdate();
      Notify.create({ title: 'Sync complete!', type: 'success' });
    }).catch(() => {
      Notify.create({ title: 'Error during sync. Please try again!', type: 'error' });
    }).finally(() => {
      this.setState({ isSyncingInDesign: false });
    });
  }

  onAssetHover = (item, hoverId, pointerOffset, listView) => {
    if (item.id === hoverId) {
      // nuh uh uh, you can't drag and drop onto yourself. nice try tho
      if (this.state.insertCaret || this.state.insertIndex) {
        this.setState({
          insertCaret: null,
          insertIndex: null,
        });
      }
      return undefined;
    }

    // Additionally, keep users from thinking they can drag assets between sections.
    // Maybe one day we will add this, but today is not that day.
    if (item.sectionId !== this.props.section.section_key) {
      return undefined;
    }

    const draggedAsset = this.props.assets.find((asset) => asset.id === item.id);
    const hoveredAsset = this.props.assets.find((asset) => asset.id === hoverId);

    const dragIndex = draggedAsset ? draggedAsset.attributes.position : -1;
    const hoverIndex = hoveredAsset ? hoveredAsset.attributes.position : -1;

    const hoverNode = findDOMNode(this.assetRefs[hoverId]); // eslint-disable-line react/no-find-dom-node
    const {
      x: hoverX,
      y: hoverY,
      width: hoverWidth,
      height: hoverHeight,
    } = hoverNode.getBoundingClientRect();

    const middleX = hoverX + (hoverWidth / 2);
    const middleY = hoverY + (hoverHeight / 2);
    let direction;
    let insertIndex;
    if (listView) {
      if (pointerOffset.y < middleY) {
        // Insert before element
        direction = 'top';
        insertIndex = hoverIndex;
      } else {
        // Insert after element
        direction = 'bottom';
        insertIndex = hoverIndex + 1;
      }
    } else if (pointerOffset.x < middleX) {
      // Insert before element
      direction = 'left';
      insertIndex = hoverIndex;
    } else {
      // Insert after element
      direction = 'right';
      insertIndex = hoverIndex + 1;
    }

    const adjustedIndex = insertIndex > dragIndex ? insertIndex - 1 : insertIndex;

    if (dragIndex === insertIndex || adjustedIndex === dragIndex) {
      return undefined;
    }

    const existingCaret = this.state.insertCaret;
    // Let's be judicious about our calls to setState...only call it if things have changed
    if (!existingCaret || (existingCaret && (direction !== existingCaret.direction || hoverId !== existingCaret.id))) {
      this.setState({
        insertIndex: adjustedIndex,
        insertCaret: {
          id: hoverId,
          direction,
        },
      });
    }

    return undefined;
  }

  onDrop = (item) => {
    const {
      section,
      authenticityToken,
    } = this.props;
    const { insertIndex } = this.state;

    if (insertIndex === null) { return undefined; }

    const sectionKey = section.section_key;

    moveAsset({
      sectionKey,
      type: section.default_asset_type,
      assetId: item.id,
      index: insertIndex,
      token: authenticityToken,
    }).then(() => {
      refreshSection(sectionKey);
    }).catch((response) => {
      Notify.create(response);
    });

    return undefined;
  }

  onDragComplete = () => {
    this.setState({
      insertIndex: null,
      insertCaret: null,
    });
  }

  isNotActiveSection() {
    const { section, activeSection, userViewOptions } = this.props;
    if (activeSection === "all" || !userViewOptions?.showSections) {
      return false;
    }

    return activeSection && activeSection !== section.section_key;
  }

  removeReloadListener() {
    const { section, onUpdate } = this.props;
    window.removeEventListener(
      `refreshSection${section.section_key}`,
      onUpdate,
      false
    );
  }

  previousLabel() {
    const { currentPage } = this.props;
    return (
      <div
        className={`page-arrow previous ${currentPage === 1 ? "disabled" : ""}`}
      >
        <span aria-hidden="true" className="bff-arrow-left" />
        <p className="pagination-label"><Trans>Previous</Trans></p>
      </div>
    );
  }

  nextLabel() {
    const { currentPage, totalPages } = this.props;
    return (
      <div
        className={`page-arrow next ${
          currentPage === totalPages ? "disabled" : ""
        }`}
      >
        <p className="pagination-label"><Trans>Next</Trans></p>
        <span aria-hidden="true" className="bff-arrow-right" />
      </div>
    );
  }

  addReloadListener() {
    const { section, onUpdate } = this.props;
    window.addEventListener(
      `refreshSection${section.section_key}`,
      onUpdate,
      false
    );
  }

  sectionSelected() {
    const { selectedAssetKeys, assets } = this.props;

    if (assets.length === 0 || selectedAssetKeys.size < assets.length) {
      // optimization: the whole section can't be selected if there's a size mismatch here
      return false;
    }
    return assets.reduce((a, asset) => a && selectedAssetKeys.has(asset.id), true);
  }

  getAssetKeys() {
    const { assets } = this.props;
    return assets.map((asset) => asset.id);
  }

  getViewOnlyAssetKeys() {
    const { assets } = this.props;
    return assets.filter((asset) => asset.attributes?.view_only).map((asset) => asset.id);
  }

  renderUploadForm() {
    const { section } = this.props;
    Notify.makeAll("dormant");
    const type = oldType(section.default_asset_type);
    const url = `/${Brandfolder.slug}/sections/${
      section.section_key
    }/ui/${type}/new`;
    BF.dialog.render(
      `New ${BF.fx.friendlyName(BF.fx.singularize(type))}`,
      url,
      BF.handlerGroups.newAsset
    );
  }

  renderTemplateInfo() {
    const { section, editable, printuiSectionId } = this.props;
    const { isSyncingInDesign } = this.state;

    if (printuiSectionId !== section.id) return null;

    const templateInfo = [];
    const templateIcon = (
      <FontIcon
        aria-hidden
        className="template-icon"
        icon={FontIcons.TemplateEditor}
      />
    );

    if (editable) {
      templateInfo.push(
        <StandardTooltip
          className="template-section-tooltip"
          id="template-section-tooltip"
          placement={Placements.RightCenter}
          tooltip={<Trans>Templates sync on page refresh, and will display when available</Trans>}
          triggerOffset={10}
        >
          {templateIcon}
        </StandardTooltip>
      );
    } else {
      templateInfo.push(templateIcon);
    }

    if (getCurrentUserIsAdminPlus(BFG.currentUser)) {
      templateInfo.push(
        <StandardTooltip
          className="template-section-syncing-tooltip"
          id="template-section-syncing-tooltip"
          placement={Placements.RightCenter}
          tooltip={isSyncingInDesign
            ? <Trans>Syncing with InDesign</Trans>
            : <Trans>Sync templates with InDesign</Trans>
          }
        >
          <IconButton
            className="sync-templates-button"
            icon={FontIcons.Refresh}
            label={t`Sync templates`}
            loaderLabel={t`Loading templates`}
            loading={isSyncingInDesign}
            look={ButtonLooks.Default}
            onClick={this.syncInDesign}
          />
        </StandardTooltip>
      );
    }

    return templateInfo;
  }

  renderPageCount() {
    const { assets, currentPage, totalAssets, totalPages } = this.props;
    const countPerPage = assets.length;
    let start, end;
    if (totalAssets > countPerPage) {
      start = 1;
      if (currentPage !== 1) {
        if (currentPage === totalPages) {
          start = totalAssets - assets.length + 1;
          end = totalAssets;
        } else {
          start = (currentPage - 1) * countPerPage + 1;
          end = start + assets.length - 1;
        }
      }
    }

    return (
      <p className="asset-count">
        {start && end ? (
          <Plural
            one={t`${start} - ${end} of ${totalAssets} asset`}
            other={t`${start} - ${end} of ${totalAssets} assets`}
            value={countPerPage}
          />
        ) : (
          <Plural
            one={<Trans>{`${totalAssets} Asset`}</Trans>}
            other={<Trans>{`${totalAssets} Assets`}</Trans>}
            value={totalAssets}
          />
        )}
      </p>
    );
  }

  renderLoadingCards() {
    const { assets } = this.props;
    const screenWidth = window.innerWidth;
    let placeholderCards;
    if (assets.length) {
      placeholderCards = assets.length + 1;
    } else {
      placeholderCards = Math.trunc(screenWidth / 340);
    }
    const cards = [];

    for (let i = 0; i < placeholderCards; i += 1) {
      cards.push(
        <div key={`card-placeholder=${i}`} className="card-placeholder" />
      );
    }

    return (
      <div className="card-list-loading">
        {cards}
      </div>
    );
  }

  renderAssets(activeLabelKey) {
    if (this.props.loading) {
      return (
        <>{this.renderLoadingCards()}</>
      );
    }

    const { insertCaret } = this.state;
    const {
      activeSection,
      assets,
      canvaApi,
      customFieldKeys,
      dependentCustomFields,
      enableContactSheets,
      isPreview,
      lazyLoadCards,
      libraryName,
      onAssetProcessing,
      onNewAssets,
      section,
      selectedAssetKeys,
      selectedViewOnlyAssetKeys,
      downloadRequestId,
      shiftSelect,
      tasks,
      userViewOptions,
    } = this.props;

    const defaultAssetType = section.default_asset_type;
    let insertCaretDirection = '';
    let insertCaretId;
    if (insertCaret) {
      insertCaretDirection = insertCaret.direction;
      insertCaretId = insertCaret.id;
    }

    const customFieldsKeys = processCustomFields(assets);

    return (
      <SectionsContext.Consumer>
        {({
          editable,
          listView,
          parentBrandfolders,
          showSections,
          sortOption,
        }) => {
          const disableSort = BFG.resource?.type !== 'brandfolder'
            || !BFG.brandfolderSettings?.allow_positioning
            || (sortOption !== "position ASC")
            || !editable
            || !userViewOptions.showSections;
          const disableUpload = !editable || BF_Manifest || (!showSections && activeSection === 'all');

          return (
            <div
              className={`card-list ${defaultAssetType === "Text" ? "text-list" : ""} ${disableSort ? "disable-sort" : ""} ${isPreview ? "card-list__preview" : ""} ${!userViewOptions.showSections ? "no-sections" : ""}`}
            >
              {!disableUpload && (
                <AssetUploader
                  activeLabelKey={activeLabelKey}
                  canvaApi={canvaApi}
                  customFieldKeys={customFieldKeys}
                  defaultAssetType={section.default_asset_type}
                  dependentCustomFields={dependentCustomFields}
                  libraryName={libraryName}
                  onAssetProcessing={onAssetProcessing}
                  onNewAssets={onNewAssets}
                  sectionKey={section.section_key}
                  showAsGrid={userViewOptions.showAsGrid}
                />
              )}
              {listView && assets.length > 0 && (
                <div className="list-view-headers">
                  {userViewOptions.showCustomFields ? (
                    <>
                      <h4 className="header-copy asset-name"><Trans>Name</Trans></h4>
                      {customFieldsKeys.map((key) => (
                        <h4 key={key} className="header-copy custom-field-key">{key}</h4>
                      ))}
                      {/* placeholder for the 50px action menu width */}
                      <span className="list-view-headers-placeholder" />
                    </>
                  ) : (
                    <>
                      <h4 className="header-copy asset-name"><Trans>Name</Trans></h4>
                      <h4 className="header-copy asset-tags"><Trans>Tags</Trans></h4>
                      {/* placeholder for the 50px action menu width */}
                      <span className="list-view-headers-placeholder" />
                    </>
                  )}
                </div>
              )}
              {assets.map((asset) => {
                const task = tasks.find(({ attributes }) => attributes.asset_key === asset.id);
                return (
                  <Asset
                    key={asset.id}
                    ref={(ref) => { this.assetRefs[asset.id] = ref; }}
                    asset={asset}
                    customFieldsKeys={customFieldsKeys}
                    downloadRequestId={downloadRequestId}
                    enableContactSheets={enableContactSheets}
                    insertCaretDirection={asset.id === insertCaretId ? insertCaretDirection : ''}
                    lazyLoadCards={lazyLoadCards}
                    onDragComplete={this.onDragComplete}
                    onDrop={this.onDrop}
                    onMove={(item, hoverId, pointerOffset) => this.onAssetHover(item, hoverId, pointerOffset, listView)}
                    parentBrandfolders={parentBrandfolders}
                    reorderable={!disableSort}
                    section={section}
                    selected={selectedAssetKeys.has(asset.id)}
                    selectedAssetKeys={selectedAssetKeys}
                    selectedViewOnlyAssetKeys={selectedViewOnlyAssetKeys}
                    shiftSelect={shiftSelect}
                    task={task}
                    toggleSelected={this.handleToggleSelected}
                    userViewOptions={userViewOptions}
                  />)
              })}
            </div>
          );
        }}
      </SectionsContext.Consumer>
    );
  }

  renderShroud({ editable }) {
    const { assets } = this.props;
    const assetCount = editable ? assets.length + 1 : assets.length;
    return (
      <SectionShroud
        assetCount={assetCount}
        onShown={() => {
          this.setState({ isRendered: true });
        }}
      />
    );
  }

  render() {
    const {
      allAssetsSelected,
      assets,
      isPreview,
      lazyLoadCards,
      loading,
      section,
      totalAssets,
      totalPages,
      userViewOptions
    } = this.props;

    const {
      isRendered,
    } = this.state;

    const assetCount = assets.length;

    return (
      <SectionsContext.Consumer>
        {({ activeLabelKey, editable, listView }) => {
          if (
            (BF_Manifest && assets.length <= 0)
            || (assets.length <= 0 && !editable)
          ) {
            return null;
          }

          const visibleChecked = this.sectionSelected() && !allAssetsSelected;

          return (
            <article
              className={`section-container
                ${this.isNotActiveSection() ? "hidden" : ""}
                ${listView ? "list-view" : "card-view"}`}
              id={section.section_key}
            >
              <div className={`section-header no-print ${isPreview ? "section-header__remove-sticky" : ""}`}>
                <div className="section-title-count">
                  <div className="section-title-checkbox-container">
                    <div className="section-title-checkbox-wrapper no-print">
                      <Checkbox
                        checked={totalPages > 1 ? allAssetsSelected : visibleChecked}
                        partiallySelected={visibleChecked && totalPages > 1 ? 'partially-selected' : null}
                        size="md"
                        toggle={() => this.toggleSelectedAssets('page', { activeLabelKey })}
                      />
                      {totalPages > 1 && userViewOptions.showSections && (
                        <ul className="section-title-selection-options">
                          <li>
                            <Checkbox
                              checked={visibleChecked}
                              labelHtml={(
                                <>
                                  <Trans>Current page</Trans>
                                  <span className="section-title-selection-options__asset_count">
                                    <Plural
                                      one="# asset"
                                      other="# assets"
                                      value={assetCount}
                                    />
                                  </span>
                                </>
                              )}
                              size="md"
                              toggle={() => this.toggleSelectedAssets('page', { activeLabelKey })}
                            />
                          </li>
                          <li>
                            <Checkbox
                              checked={allAssetsSelected}
                              labelHtml={(
                                <>
                                  <Trans>All pages</Trans>
                                  <span className="section-title-selection-options__asset_count">
                                    <Plural
                                      one="# asset"
                                      other="# assets"
                                      value={totalAssets}
                                    />
                                  </span>
                                </>
                              )}
                              size="md"
                              toggle={() => this.toggleSelectedAssets('all', { activeLabelKey })}
                            />
                          </li>
                        </ul>
                      )}
                    </div>
                    <p className="section-title">{section.name}</p>
                    {this.renderTemplateInfo()}
                  </div>
                  {this.renderPageCount()}
                </div>
                {totalPages > 1 ? (
                  <ReactPaginate
                    activeClassName="active"
                    breakClassName="break-me"
                    breakLabel="..."
                    containerClassName={`pagination${loading ? ' pagination-loading' : ''}`}
                    marginPagesDisplayed={1}
                    nextLabel={this.nextLabel()}
                    onPageChange={this.goToPage}
                    pageCount={this.maxPageLimit()}
                    pageRangeDisplayed={3}
                    previousLabel={this.previousLabel()}
                    subContainerClassName="page-box"
                  />
                ) : (
                  ""
                )}
              </div>
              {isRendered || !lazyLoadCards ? this.renderAssets(activeLabelKey) : this.renderShroud({ editable })}
            </article>
          );
        }}
      </SectionsContext.Consumer>
    );
  }
}

Section.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape({})),
  activeSection: PropTypes.string,
  authenticityToken: PropTypes.string,
  canvaApi: PropTypes.shape({}),
  customFieldKeys: PropTypes.shape({}),
  dependentCustomFields: PropTypes.arrayOf(PropTypes.shape({})),
  section: PropTypes.shape({
    default_asset_type: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    section_key: PropTypes.string,
  }).isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  selectedViewOnlyAssetKeys: PropTypes.instanceOf(Set).isRequired,
  toggleSelected: PropTypes.func.isRequired,
  shiftSelect: PropTypes.func.isRequired,
  printuiSectionId: PropTypes.number,
  editable: PropTypes.bool,
  onNewAssets: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  totalAssets: PropTypes.number,
  libraryName: PropTypes.string,
  loading: PropTypes.bool,
  isRendered: PropTypes.bool,
  allAssetsSelected: PropTypes.bool,
  searchQuery: PropTypes.string,
  onAssetProcessing: PropTypes.func,
  lazyLoadCards: PropTypes.bool,
  enableContactSheets: PropTypes.bool,
  userViewOptions: PropTypes.shape({
    assetsPerPage: PropTypes.number,
    searchOperator: PropTypes.oneOf(Object.values(SearchOperatorOptions)),
    showAsGrid: PropTypes.bool,
    showCustomFields: PropTypes.bool,
    showEmptySections: PropTypes.bool,
    sortOrder: PropTypes.string,
    showSections: PropTypes.bool,
  }),
  isPreview: PropTypes.bool,
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  downloadRequestId: PropTypes.number
};

Section.defaultProps = {
  activeSection: "all",
  allAssetsSelected: false,
  assets: [],
  authenticityToken: null,
  canvaApi: null,
  currentPage: 1,
  customFieldKeys: null,
  dependentCustomFields: null,
  downloadRequestId: null,
  editable: false,
  enableContactSheets: false,
  isPreview: false,
  isRendered: true,
  lazyLoadCards: true,
  libraryName: 'Brandfolder',
  loading: true,
  onAssetProcessing: () => {},
  printuiSectionId: null,
  searchQuery: null,
  tasks: null,
  totalAssets: 0,
  totalPages: 1,
  userViewOptions: {},
};

export default Section;
